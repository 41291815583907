* {
  font-family: "Comic Sans MS", cursive, sans-serif;
  text-align: center;
}

h1 {
  margin-top: 2em;
}

img {
  margin-top: 2em;
  max-width: 40%;
  animation: roll 10s infinite;
  transform: rotate(30deg);
}

@keyframes roll {
  0% {
      transform: rotate(0);
  }
  100% {
      transform: rotate(360deg);
  }
}


@media screen and (max-width: 911px) {
  img {
      min-width: 95%;
  }
}